export interface Config {
  auth0: {
    domain: string;
    clientId: string;
  };
  api: {
    private_url: string;
    public_url: string;
    speed_dating_url?: string;
    belong_exp_url?: string;
  };
  sentry: {
    enabled: boolean;
  };
  app: {
    defaultMobileRoom: string;
    defaultDesktopRoom: string;
    disableZoneJump?: boolean;
  };
  avatar?: {
    modelUrl?: string
  },
  loadingPage?: {
    modelUrl?: string
  },
  landingPage: {
    variant?: "default" | string
    heroLogo: string;
    hideMenu?: boolean;
    menuLogo: string;
    menuLogoHref: string;
    backgroundVideo: string;
    backgroundVideoPoster?: string;
    links: {
      label: string;
      href: string;
    }[];
  };
}

const local: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://p2sm60fb78.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://bqp6b8wknd.execute-api.eu-west-2.amazonaws.com",
    speed_dating_url: "https://lvjzzhom12.execute-api.eu-west-2.amazonaws.com",
    belong_exp_url: "https://6qx3cmua51.execute-api.eu-west-2.amazonaws.com", // TODO: TO BE UPDATED ONCE DEPLOYED
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "mastercardgis",
    defaultDesktopRoom: "mastercardgis",
  },
  avatar: {
    modelUrl: "assets/models/mastercard-client.glb"
  },
  loadingPage: {
    modelUrl: "assets/models/mastercard/mcSculpture.glb",
  },
  landingPage: {
    variant: "mastercard",
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://zonevs.io",
    links: [
      {
        label: "About",
        href: "/about",
      },
      {
        label: "Pricing",
        href: "/pricing",
      },
    ],
  },
};

const dev: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://p2sm60fb78.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://bqp6b8wknd.execute-api.eu-west-2.amazonaws.com",
    speed_dating_url: "https://lvjzzhom12.execute-api.eu-west-2.amazonaws.com",
    belong_exp_url: "https://6qx3cmua51.execute-api.eu-west-2.amazonaws.com", // TODO: TO BE UPDATED ONCE DEPLOYED
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "festiverse",
    defaultDesktopRoom: "exhibitionSpace",
  },
  landingPage: {
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://zonevs.io",
    links: [
      {
        label: "About",
        href: "https://zonevs.io/about",
      },
      {
        label: "Pricing",
        href: "https://zonevs.io/pricing",
      },
    ],
  }
};

const prod: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://31z66kaiwh.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://dlib0mavh7.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "festiverse",
    defaultDesktopRoom: "exhibitionSpace",
  },
  landingPage: {
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://zonevs.io",
    links: [
      {
        label: "About",
        href: "https://zonevs.io/about",
      },
      {
        label: "Pricing",
        href: "https://zonevs.io/pricing",
      },
    ],
  },
};

const oasisdigitalgallery: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://g5vavgrojl.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://c32m2su6t1.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "oasisdigitalgallerymobile",
    defaultDesktopRoom: "oasisdigitalgallery",
    disableZoneJump: true,
  },
  landingPage: {
    backgroundVideo: "assets/videos/oasis-bgvideo_02.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/oasis-logo-welcome.svg",
    menuLogo: "assets/logos/oasis-logo.svg",
    menuLogoHref: "/",
    links: [
      {
        label: "Privacy",
        href: "/oaprivacy",
      },
      {
        label: "About",
        href: "/oaabout",
      },
      {
        label: "Contact",
        href: "/oacontact",
      },
      {
        label: "Instructions",
        href: "/oainstructions",
      },
    ],
  },
};

const festiverse: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://ct9679wl7a.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://7jtmxg6ik7.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "festiverse",
    defaultDesktopRoom: "festiverse",
  },
  landingPage: {
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://zonevs.io",
    links: [
      {
        label: "About",
        href: "https://zonevs.io/about",
      },
      {
        label: "Pricing",
        href: "https://zonevs.io/pricing",
      },
    ],
  },
};

const mastercard_global_dei_summit: Config = {
  auth0: {
    clientId: "7fOkVeKr3W7BW8C8g0uVypQEKrXhQfOT",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://dhx02xns75.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://2e3o62r1hl.execute-api.eu-west-2.amazonaws.com",
    speed_dating_url: "https://7mezb8xqh7.execute-api.eu-west-2.amazonaws.com",
    belong_exp_url: "https://0opagdjiqf.execute-api.eu-west-2.amazonaws.com",
    // obtain from https://eu-west-2.console.aws.amazon.com/cloudformation/home?region=eu-west-2#/stacks/
    // go to "outputs" tab
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "mastercardgis",
    defaultDesktopRoom: "mastercardgis",
  },
  loadingPage: {
    modelUrl: "assets/models/mastercard/mcSculpture.glb",
  },
  landingPage: {
    variant: "mastercard",
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://mastercard-global-dei-summit.zonevs.io",
    links: [
      // {
      //   label: "About",
      //   href: "https://zonevs.io/about",
      // },
      // {
      //   label: "Pricing",
      //   href: "https://zonevs.io/pricing",
      // },
    ],
  },
};

const mastercard_gis: Config = {
  auth0: {
    clientId: "7fOkVeKr3W7BW8C8g0uVypQEKrXhQfOT",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://dhx02xns75.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://2e3o62r1hl.execute-api.eu-west-2.amazonaws.com",
    speed_dating_url: "https://k9aly51r08.execute-api.eu-west-2.amazonaws.com",
    belong_exp_url: "https://6qx3cmua51.execute-api.eu-west-2.amazonaws.com", // TODO: TO BE UPDATED ONCE DEPLOYED
    // obtain from https://eu-west-2.console.aws.amazon.com/cloudformation/home?region=eu-west-2#/stacks/
    // go to "outputs" tab
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "mastercardgis",
    defaultDesktopRoom: "mastercardgis",
  },
  loadingPage: {
    modelUrl: "assets/models/mastercard/mcSculpture.glb",
  },
  landingPage: {
    variant: "mastercard",
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://mastercard-global-dei-summit.zonevs.io",
    links: [
      // {
      //   label: "About",
      //   href: "https://zonevs.io/about",
      // },
      // {
      //   label: "Pricing",
      //   href: "https://zonevs.io/pricing",
      // },
    ],
  },
};

const idea_pharma: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://dhx02xns75.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://2e3o62r1hl.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "ideaPharmaInnoverse",
    defaultDesktopRoom: "ideaPharmaInnoverse",
  },

  landingPage: {
    variant: "ideaPharmaInnoverse",
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://ideaPharmaInnoverse.zonevs.io",
    links: [
      // {
      //   label: "About",
      //   href: "https://zonevs.io/about",
      // },
      // {
      //   label: "Pricing",
      //   href: "https://zonevs.io/pricing",
      // },
    ],
  },
};

const gresham: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://fk4bw7ek66.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://i3kith8vug.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "gresham",
    defaultDesktopRoom: "gresham",
  },

  landingPage: {
    variant: "gresham",
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://gresham.zonevs.io",
    links: [
      // {
      //   label: "About",
      //   href: "https://zonevs.io/about",
      // },
      // {
      //   label: "Pricing",
      //   href: "https://zonevs.io/pricing",
      // },
    ],
  },
};

const stic: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://igvu9lq6de.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://lyu2kp91yk.execute-api.eu-west-2.amazonaws.com",
    },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "stic",
    defaultDesktopRoom: "stic",
  },

  landingPage: {
    variant: "stic",
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://stic.zonevs.io",
    links: [
      // {
      //   label: "About",
      //   href: "https://zonevs.io/about",
      // },
      // {
      //   label: "Pricing",
      //   href: "https://zonevs.io/pricing",
      // },
    ],
  },
};

const aispace: Config = {
  auth0: {
    clientId: "xmyYQSnj32oqMD1kzyScsOb85sHItDnp",
    domain: "zonevs.eu.auth0.com"
  },
  api: {
    private_url: "https://nk4z6kmm2g.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://buumrcl5kb.execute-api.eu-west-2.amazonaws.com",

    },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "aispace",
    defaultDesktopRoom: "aispace",
  },

  landingPage: {
    variant: "stic",
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://stic.zonevs.io",
    links: [
      // {
      //   label: "About",
      //   href: "https://zonevs.io/about",
      // },
      // {
      //   label: "Pricing",
      //   href: "https://zonevs.io/pricing",
      // },
    ],
  },
};

// Default to dev if not set
const config = (function () {
  console.log(`Config using '${process.env.REACT_APP_STAGE}' stage.`);

// comment out before deployment - this forces a stage config
//remember to revert cell size to 7****
// return idea_pharma;
// return   mastercard_global_dei_summit;


  switch (process.env.REACT_APP_STAGE) {
    case "mastercard-gis":
      return mastercard_gis; // The -gis stage will now use the newly created config. THANKS!! ALL GOOD NOW I GUESS? JUST REDEPLOY GIS
    case "mastercard-global-dei-summit":
      return mastercard_global_dei_summit;
    case "idea-innoverse":
      return idea_pharma;
    case "gresham":
      return gresham;
    case "stic":
      return stic;
    case "aispace":
      return aispace;
    case "oasisdigitalgallery":
      return oasisdigitalgallery;
    case "festiverse":
      return festiverse;
    case "prod":
      return prod;
    case "local":
      return local
    case "dev":
      return dev;
    default:
      return dev;
  }
})();

export default {
  ...config,
};
